.responsive {
	height: 0;
	margin-bottom: $global-gutter;
	overflow: hidden;
	position: relative;
	padding-bottom: 56.25%;

	&-wide {
		padding-bottom: 44%;
	}

	> div,
	> iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
