.banner {

	&::before {
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
		bottom: 0;
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		width: 100%;
		z-index: 1;
	}

	&-content {
		padding: $global-margin 0 $global-medium-margin;
		z-index: 2;
		min-height: calc(60vh - 80px);

		h1 {
			margin: 0;
		}

		p {
			margin-top: $global-small-margin;
		}

		.uk-button {
			min-width: 282px;

			@media (max-width: $breakpoint-mobile) {
				font-size: 15px;
				min-width: 0;
			}
		}
	}

	@media (min-width: $breakpoint-small) {
		&-content {
			padding-bottom: $global-large-margin;
		}
	}

	@media (min-width: $breakpoint-medium) {
		[data-uk-cover] {
			top: 40%;
		}

		.uk-text-lead {
			font-size: 34px;
		}
	}
}
