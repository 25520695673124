.header {
	padding: 12px 0;

	.uk {

		&-logo {

			img {
				width: 140px;
			}
		}
	}

	.uk-navbar-search {
		z-index: 1025;
	}

	@media (min-width: $breakpoint-small) {
		.uk {

			&-logo {

				img {
					width: 180px;
				}
			}
		}
	}
}