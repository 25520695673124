/*body,
html {
	overflow-x: hidden;
}*/

[data-uk-lightbox] a {
	cursor: zoom-in;
}

* {
	box-sizing: border-box;

	&::before,
	&::after {
		box-sizing: border-box;
	}
}

a,
button {
	transition: $transition;
}

.icon {
	height: 18px;
}

.thumb {
	width: 100%;
	height: 320px;
}