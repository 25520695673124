@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-ExtraLight.woff2') format('woff2'),
        url('../fonts/SourceSansPro-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-BoldItalic.woff2') format('woff2'),
        url('../fonts/SourceSansPro-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/SourceSansPro-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-LightItalic.woff2') format('woff2'),
        url('../fonts/SourceSansPro-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-Bold.woff2') format('woff2'),
        url('../fonts/SourceSansPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-Light.woff2') format('woff2'),
        url('../fonts/SourceSansPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-ExtraLightItalic.woff2') format('woff2'),
        url('../fonts/SourceSansPro-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-Regular.woff2') format('woff2'),
        url('../fonts/SourceSansPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-Black.woff2') format('woff2'),
        url('../fonts/SourceSansPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-Italic.woff2') format('woff2'),
        url('../fonts/SourceSansPro-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-SemiBold.woff2') format('woff2'),
        url('../fonts/SourceSansPro-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../fonts/SourceSansPro-BlackItalic.woff2') format('woff2'),
        url('../fonts/SourceSansPro-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

