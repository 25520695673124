.nb-form {

	+ .nb-form {
		margin-top: $global-gutter;
	}

	&-content {
		position: relative;

		ul {
			margin: 0 0 $global-small-margin;
			padding: 0;

			li {
				display: inline-block;
				list-style: none;
				width: 100%;
			}
		}

		.InputfieldRadiosFloated {

			li {
				padding-right: $global-gutter;
				width: auto;
			}
		}

		[aria-invalid='true'] {
			border-color: $danger;
		}
	}

	.uk-checkbox {

		+ span {
			display: inline-block;
			margin-left: $global-small-margin;
		}
	}

	.uk-radio {

		+ span {
			display: inline-block;
			margin-left: $global-small-margin;
		}
	}

	.InputfieldToggle {

		.InputfieldToggleHelper {
			display: none !important;
		}
	}

	&-error {
		font-size: $global-small-font-size;
		font-weight: $base-strong-font-weight;
		margin-bottom: 5px; // math.div($global-small-margin, 2);
	}

	.g-recaptcha {
		margin-bottom: $global-gutter;
	}
}
