@media print {

	*,
	::after,
	::before,
	::marker,
	.icon {
		background: 0 0 !important;
		box-shadow: none !important;
		color: $black !important;
		fill: $black !important;
		font-weight: 400 !important;
		text-align: left !important;
		text-shadow: none !important;
	}

	.uk-text-center {
		text-align: left !important;
	}

	.icon {
		filter: brightness(0) !important;
		height: 24px !important;
	}

	.header,
	.footer,
	img,
	.banner::before,
	.filters .uk-form-label,
	.thumb,
	.uk-button,
	.uk-card-footer,
	.uk-breadcrumb,
	.uk-pagination,
	[data-uk-tab],
	.map,
	.uk-modal,
	.nb-form {
		display: none !important;
	}

	.banner,
	.banner>.uk-container,
	.banner-content,
	.uk-container,
	.uk-section,
	.uk-section-large,
	[data-uk-grid],
	.uk-alert,
	.uk-table,
	.uk-width-1-2\@s,
	.uk-child-width-1-2\@m>*,
	.uk-width-2-3\@m,
	.uk-width-3-4\@m,
	.uk-width-3-5\@m,
	.uk-width-2-5\@m,
	.uk-width-2-3\@l,
	.uk-width-3-4\@l,
	.uk-card-media-top,
	.uk-width-1-3\@m,
	.uk-child-width-1-2\@s>* {
		margin: 0 !important;
		max-width: none !important;
		min-height: 0 !important;
		height: auto !important;
		padding: 0 5px !important;
		width: 100% !important;
		text-align: left !important;
	}

	.uk-grid>* {
		padding: 0 !important;

		.uk-grid {
			padding: 0 !important;
		}
	}

	*+.uk-grid-margin,
	.uk-grid+.uk-grid,
	.uk-grid>.uk-grid-margin {
		margin-top: 0 !important;
	}

	.uk-card-body {
		padding: 15px !important;
	}

	.uk-switcher>li {
		display: block !important;
	}
}