//
// Main
//
// ========================================================================

//
// Typography
//

// Family
$font-default: 'Source Sans Pro', sans-serif;

// Size
$font-size: 18px;

// Weight
$light: 300;
$normal: 400;
$semi-bold: 600;
$bold: 700;
$font-black: 900;

// Line Height
$line-height: 1.5;


//
// Colours
//
$white: #fff;
$black: #000;
$primary: #035aa6;
$secondary: $black;
$blue: #035aa6;
$success: #32d296;
$warning: #faa05a;
$danger: #f0506e;
$grey: lighten($black, 32%);
$light-grey: lighten($grey, 32%);
$off-white: lighten($light-grey, 20%);


//
// Other
//

// Animation
$speed: 256ms;
$speed-fast: 128ms; // math.div($speed, 2);
$speed-slow: $speed * 2;
$timing: ease-in-out;
$transition: $speed $timing;


//
// UIkit Global Variables
// https://github.com/uikit/uikit/blob/master/src/scss/components/variables.scss
//
// Imports UIkit variable overrides for the theme
// These may be used by main.scss
//
// ========================================================================

@import 'uikit/variables';
