.footer {
	&-top {
		padding: $global-medium-margin 0;
		border-bottom: 1px solid rgba($white, .3);
	}

	&-bottom {
		padding: $global-margin 0 $global-small-margin;
	}

	.legal {
		padding: 0;

		li {
			display: inline-block;
			list-style: none;
			margin: 0;
			padding: 0;
		}
	}

	.watermark {

		a {
			align-items: center;
			display: inline-flex;
			font-weight: 700;

			@include hover {
				color: #e11e2f;
				text-decoration: none;
			}

			img {
				height: 1rem;
				margin-left: 5px;
				position: relative;
				bottom: 1px;
				width: auto;
			}
		}
	}

	.uk-button-social {
		border: 2px solid $white;

		@include hover {
			background-color: $white;
			color: $primary;
		}
	}

	@media (min-width: $breakpoint-medium) {
		&-top {
			padding: 60px 0 $global-medium-margin;
		}
	}
}