//
// Breakpoints
//
$breakpoint-small: 640px;
$breakpoint-medium: 960px;
$breakpoint-large: 1200px;
$breakpoint-xlarge: 1600px;
$breakpoint-xsmall-max: ($breakpoint-small - 1);
$breakpoint-small-max: ($breakpoint-medium - 1);
$breakpoint-medium-max: ($breakpoint-large - 1);
$breakpoint-large-max: ($breakpoint-xlarge - 1);
$breakpoint-mobile: 479px; // Custom


//
// Global Variables
//

// Typography
$global-font-family: $font-default;
$global-font-size: $font-size;
$global-line-height: $line-height;
$global-2xlarge-font-size: 72px;
$global-xlarge-font-size: 2rem;
$global-large-font-size: 1.5rem;
$global-medium-font-size: 1.25rem;
$global-small-font-size: 0.875rem;

// Colors
$global-color: $black;
$global-emphasis-color: $primary;
$global-muted-color: $grey;
$global-link-color: $primary;
$global-link-hover-color: $primary;
$inverse-global-color: $white;

// Backgrounds
$global-background: $white;
$global-muted-background: $off-white;
$global-primary-background: $primary;
$global-secondary-background: $secondary;
$global-success-background: $success;
$global-warning-background: $warning;
$global-danger-background: $danger;

// Borders
$global-border-width: 1px;
$global-border: $light-grey;

// Spacings
$global-margin: 20px;
$global-small-margin: 10px;
$global-medium-margin: 40px;
$global-large-margin: 70px;
$global-xlarge-margin: 135px;
$global-gutter: 30px;
$global-small-gutter: 15px;
$global-medium-gutter: 40px;
$global-large-gutter: 70px;

// Controls
$global-control-height: 40px;
$global-control-small-height: 30px;
$global-control-large-height: 55px;


//
// Base
//
$base-body-font-weight: 400;
$base-link-hover-text-decoration: underline;
$base-strong-font-weight: $bold;
$base-code-color: inherit;
$base-em-color: inherit;
$base-selection-background: $secondary;
$base-heading-font-weight: $font-black;
$base-h1-font-size: 46px;
$base-h1-line-height: 1.1;


//
// Form
//
$form-background: $white;
$form-color: $global-color;
$form-focus-background: $white;
$form-focus-color: $global-color;
$form-border: $global-color;
$form-focus-border: $primary;
$form-radio-border: $global-color;
$form-label-color: $global-color;
$form-label-font-size: $global-small-font-size;


//
// Container
//
$container-max-width: 1200px;
$container-xsmall-max-width: 750px;
$container-small-max-width: 900px;
$container-large-max-width: 1400px;
$container-xlarge-max-width: 1600px;
$container-padding-horizontal: 20px;
$container-padding-horizontal-s: $global-gutter;
$container-padding-horizontal-m: $global-medium-gutter;


//
// Alert
//
$alert-primary-background: $global-primary-background;
$alert-primary-color: $white;
$alert-success-background: $global-success-background;
$alert-success-color: $white;
$alert-warning-background: $global-warning-background;
$alert-warning-color: $white;
$alert-danger-background: $global-danger-background;
$alert-danger-color: $white;


//
// Dropdown
//
$dropdown-margin: 0;


//
// Grid
//
$grid-gutter-horizontal: $global-gutter;
$grid-gutter-horizontal-l: $global-medium-gutter;
$grid-large-gutter-horizontal: $global-medium-gutter;
$grid-large-gutter-horizontal-l: $global-large-gutter;


//
// Navbar
//
$navbar-dropdown-margin: 0;
$navbar-nav-item-height: 0;
$navbar-nav-item-font-size: 18px;


//
// Breadcrumb
//
$breadcrumb-item-color: $global-muted-color;
$breadcrumb-divider: '/';
$breadcrumb-divider-margin-horizontal: $global-margin;
$breadcrumb-divider-color: $global-color;


//
// Lightbox
//
$lightbox-item-max-width: 95vw;
$lightbox-item-max-height: 85vh;


//
// Animation
//
$animation-duration: $speed;
$animation-fade-duration: $speed-slow;
$animation-fast-duration: $speed-fast;


//
// Text
//
$text-lead-font-size: 22px;
$text-lead-line-height: $global-line-height;
$text-lead-color: $global-emphasis-color;
$text-meta-color: $global-muted-color;


//
// Inverse
//
$inverse-global-muted-color: $white;


//
// Card
//
$card-body-padding-horizontal: $global-margin;
$card-body-padding-vertical: $global-margin;
$card-body-padding-horizontal-l: $global-gutter;
$card-body-padding-vertical-l: $global-gutter;
$card-small-body-padding-horizontal: 25px;
$card-small-footer-padding-horizontal: 25px;
$card-title-line-height: 1.2;


//
// Tab
//
$tab-margin-horizontal: 2px;
$tab-item-padding-horizontal: $global-small-margin;
$tab-item-padding-vertical: $global-small-gutter;
$tab-item-color: $white;
$tab-item-hover-color: $white;
$tab-item-active-color: $white;
$tab-item-disabled-color: $white;


//
// Button
//
$button-primary-background: $blue;