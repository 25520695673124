.video {

	.uk-cover-container {
		padding-bottom: 56.25%;

		&::before,
		&::after {
			position: absolute;
			content: '';
			display: block;
			left: 50%;
			top: 50%;
			transition: $transition;
			transform: translate(-50%, -50%);
			pointer-events: none;
		}

		&::before {
			border-radius: 50%;
			background-color: $primary;
			width: 90px;
			height: 90px;
			z-index: 1;
			transform: translate(-50%, -50%);
		}

		&::after {
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 20px 0 20px 40px;
			border-color: transparent transparent transparent $white;
			z-index: 2;
			transform: translate(-40%, -50%);
		}
	}

	@include hover {

		.uk-cover-container {

			&::before {
				background-color: $white;
			}

			&::after {
				border-color: transparent transparent transparent $primary;
			}
		}
	}

	@media (min-width: $breakpoint-mobile) {

		.uk-cover-container {
			padding-bottom: calc(56.25% - 144px);
		}
	}
}