.template-test-sites {
	main {
		min-height: 100vh;
	}

	.uk-card {
		box-shadow: 0 5px 15px rgb($black, .4);
	}

	@media (min-width: $breakpoint-small) {
		.uk-table a:not(.uk-button) {
			max-width: 375px;
		}
	}

	@media (min-width: $breakpoint-large) {
		.uk-table a:not(.uk-button) {
			max-width: 475px;
		}
	}

	@media (min-width: $breakpoint-xlarge) {
		.uk-table a:not(.uk-button) {
			max-width: 675px;
		}
	}
}