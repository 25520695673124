.filters {
	.uk-dropdown {
		padding: $global-margin;

		li {
			&.uk-active button {
				color: $primary;

				.icon {
					opacity: 1;
				}
			}
		}

		button {
			outline: none;
			border: 0;
			background-color: transparent;
			cursor: pointer;
			font-size: 16px;
			max-width: 100%;
			text-align: left;
			line-height: 1.5;
			padding: 0;

			.icon {
				position: relative;
				bottom: 1px;
				opacity: 0;
				transition: $transition;
				color: $primary;
			}

			@include hover {
				color: $primary;

				.icon {
					opacity: 1;
				}
			}
		}
	}

	@media (min-width: $breakpoint-small) {
		.uk-dropdown {
			button {
				max-width: 280px;
			}
		}
	}
}